import React, { Component } from 'react';
import styled from '@emotion/styled';

import { FdpqWidgetWrapper } from '@packages/fdpq-widget';
import { config, theme } from '@packages/fdpq-core/config/atc';

const theme1 = {
  global: {
    color: '#2E3877',
  },
  button: {
    fontWeight: 'bold',
    fontSize: '14px',
    color: '#fff',
    borderRadius: '22.5px',
    background: 'linear-gradient(#f76100,#e85b00,#de5700)',
    height: '37px',
    width: '100%',
    border: '1px solid transparent',
    cursor: 'pointer',
    hover: {
      background: 'linear-gradient(#de5700,#e85b00,#f76100);',
    },
  },
  text: {
    color: '#414c58',
    subtitle: '#2D363E',
  },
  box: {
    border: '1px solid #ccc',
    borderRadius: '3px',
    baseGlowingColor: '#0F3485',
    errorGlowingColor: '#E14545',
    height: '37px',
    lineHeight: '20px',
  },
};

const GlobalStyleProvider = styled.div`
  box-sizing: border-box;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Roboto Light,
    Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif,
    Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol;
`;

export default class vehicletest extends Component {
  render() {
    return (
      <GlobalStyleProvider>
        <FdpqWidgetWrapper
          initialData={{
            theme,
            config,
            navigation: {
              currentPage: 'VEHICLE_SELECTOR',
            },
          }}
        />
      </GlobalStyleProvider>
    );
  }
}
